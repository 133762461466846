
export default {
  props: {
    header: {
      type: String,
      default: null
    },
    text: {
      type: String,
      default: null
    },
    buttonTitle: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      displayText: this.text || this.$t('launchYourStoreNow'),
      displayButtonTitle: this.buttonTitle || this.$t('tryFree')
    };
  },
  watch: {
    text(newValue) {
      this.displayText = newValue || this.$t('launchYourStoreNow');
    },
    buttonTitle(newValue) {
      this.displayButtonTitle = newValue || this.$t('tryFree');
    }
  }
};
